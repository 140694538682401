<template>
  <div>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <div v-if="!loading">
      <b-card>
        <div class="d-flex justify-content-end my-1">
          <v-select
            v-model="year"
            label="text"
            taggable
            class="ml-1"
            placeholder="--Select Year--"
            @input="loadVulnDashboard()"
            :options="year_options"
            autocomplete
            style="width: 15%"
            :reduce="(year) => year.value"
            size="sm"
          />
          <v-select
            v-model="quarter"
            label="text"
            taggable
            class="ml-1"
            placeholder="--Select Quarter--"
            @input="loadVulnDashboard()"
            :options="quarter_options"
            :reduce="(quarter) => quarter.value"
            autocomplete
            style="width: 35%"
            size="sm"
          />
          <v-select
            v-model="group_filter_dash"
            label="text"
            taggable
            class="ml-1"
            placeholder="--Select Asset Group--"
            @input="loadVulnDashboard()"
            :options="group_filter_options"
            autocomplete
            style="width: 25%"
            :reduce="(group_filter) => group_filter.value"
            size="sm"
          />
        </div>
        <div
          class="d-flex mb-1"
          v-if="
            this.$store.state.app.user.permissions.includes(
              'organization.change_organization'
            )
          "
        >
          <span class="text-danger">*</span>
          <span style="padding-left: 3px">
            The work year starts on <b>{{ formattedStartingDate }}</b
            >.</span
          ><router-link :to="'/organization/edit/' + organizationId"
            ><span style="padding-left: 3px">Click to change </span>
          </router-link>
        </div>
        <VulnCountList
          :vulnCount="vulnDashboard"
          :groupid="group_filter_dash"
          :quarter="quarter"
          :year="year"
        />
      </b-card>
      <b-row class="match-height" id="section1">
        <b-col lg="6">
          <VulnData :vulnData="vulnDashboard" />
        </b-col>
        <b-col lg="6">
          <VulnCVSSGraph :values="cvss_values" />
        </b-col>
      </b-row>
      <b-row class="match-height" id="section1">
        <b-col lg="6">
          <MostCommonVulnerabilities :mostCommonVuln="mostCommonVuln" />
        </b-col>
        <b-col lg="6">
          <TopSevereVuln :severeVuln="severeVuln" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BCard,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BModal,
  BTabs,
  BTab,
  BAvatar,
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";
import FeatherIcon from "../../@core/components/feather-icon/FeatherIcon.vue";
import VulnCountList from "./vuln_count.vue";
import VulnData from "./vuln_data_linegraph.vue";
import VulnCVSSGraph from "./vuln_cvss_graph.vue";
import MostCommonVulnerabilities from "./most_common_vuln.vue";
import TopSevereVuln from "./top_severe_vuln.vue";

export default {
  components: {
    vSelect,
    BCard,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
    BSpinner,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BModal,
    FeatherIcon,
    VulnCountList,
    VulnData,
    VulnCVSSGraph,
    MostCommonVulnerabilities,
    TopSevereVuln,
  },
  data() {
    return {
      is_superAdmin: this.$store.state.app.user.id == 1,
      org_id: this.$store.state.app.org_id,
      workspaces: this.$store.state.app.workspaces,
      stats: {},
      tasks_completion: [],
      vuln_values: [],
      group_data: {},
      main_data_loading: true,
      group_data_loading: true,
      group_filter: [],
      group_filter_options: [],
      group_filter_dash: null,
      group_filter_asset: null,
      group_filter_scan: null,
      organizations: [],
      organization: "",
      getorg: "",
      overallrisk: {},
      overall_vuln_values: [],
      internalrisk: {},
      internal_vuln_values: [],
      internetrisk: {},
      internet_vuln_values: [],

      overallVuln: {},
      vulnData: {},
      generalCount: {},
      vulnCount: {},

      cvss_values: [],
      breached_values: [],
      breached_data: {},

      vuln_cvss: {},
      assetCount: {},
      scanCount: {},
      upcomingScans: [],
      recent_scans: [],
      scanTrend: {},
      scanCreditTrend: {},

      assets_most_affected: [],
      assets_most_exploittable: [],
      top_severe_vulnerabilities: [],
      vuln_by_age: {},
      vulnDashboard: {},
      mostCommonVuln: [],
      severeVuln: [],
      tab_id: "",
      // workspace data
      workspaceOptions: [],

      organizationId: this.$store.state.app.organizationId,
      startMonth: this.$store.state.app.starting_month,
      isPublic: "",
      orgAssetCount: 0,
      permissions: [],
      loading: false,

      // quarter: "Q1",
      // quarter_options: [
      //   { value: "Q1", text: "Quarter 1" },
      //   { value: "Q2", text: "Quarter 2" },
      //   { value: "Q3", text: "Quarter 3" },
      //   { value: "Q4", text: "Quarter 4" },
      // ],
      year: moment().year(), // Set the initial value to the current year
      year_options: [],
      quarter_options: [],
      quarter: "",
      label_options: [],
      formattedStartingDate: "",
    };
  },
  computed: {},
  created: function () {
    const startYear = moment().year() - 10; // Start from 10 years ago
    const endYear = moment().year(); // Current year
    for (let year = startYear; year <= endYear; year++) {
      this.year_options.push({
        text: year.toString(), // Displayed text in the dropdown
        value: year, // Value associated with the option
      });
    }
    this.getQuarter();
    this.load();
    // this.loadVulnDashboard();
  },
  mounted() {
    this.formatDate();
    this.getQuarter();
  },
  watch: {
    year(newVal, oldVal) {
      this.getQuarter();
    },
  },
  methods: {
    formatDate() {
      const dateObject = moment(this.startMonth, "YYYY-MM-DD");
      const formattedDate = dateObject.format("MMMM");
      if (this.startMonth) {
        this.formattedStartingDate = formattedDate;
      } else {
        this.formattedStartingDate = "January";
      }
    },
    getQuarter: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/get-quarters?year=" +
          this.year,
      };
      var self = this;
      this.$http(options).then((res) => {
        const jsonData = res.data;
        this.quarter_options = JSON.parse(jsonData).map((quarter) => ({
          value: quarter.name,
          text: `${quarter.name} - ( ${quarter.title} )`,
        }));
        if (this.quarter_options.length > 0) {
          this.quarter = this.quarter_options[0].value;
        }

        this.loadVulnDashboard();
        // this.setCurrentQuarter();
      });
    },
    // setCurrentQuarter() {
    //   const currentMonth = new Date().getMonth() + 1;
    //   let currentQuarter = "";

    //   // Find the quarter that matches the current month
    //   this.quarter_options.forEach((q) => {
    //     const months = q.title.split(' - ');
    //     const startMonth = new Date(months[0]).getMonth() + 1;
    //     const endMonth = new Date(months[1]).getMonth() + 1;

    //     if (currentMonth >= startMonth && currentMonth <= endMonth) {
    //       currentQuarter = q.value;
    //     }
    //   });

    //   // Set the current quarter as the selected value in the v-select
    //   this.quarter = currentQuarter;
    // },
    load: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/group/list-all-asset-groups?org_id=" +
          this.org_id,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        this.group_filter_options = [];
        this.group_filter_options.push({
          value: null,
          text: "--Select Asset Group--",
        });
        res.data.map(function (value, key) {
          let a = {
            value: res.data[key].group_id,
            text: res.data[key].group_name,
          };
          self.group_filter_options.push(a);
        });
      });
    },

    loadVulnDashboard: function () {
      let url =
        process.env.VUE_APP_BASEURL +
        "dashboard/v3/quarterly-dashboard?quarter=" +
        this.quarter +
        "&year=" +
        this.year;
      if (this.group_filter_dash != null) {
        url = url + "&group_id=" + this.group_filter_dash;
      }
      const options_vuln_new = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options_vuln_new).then((res) => {
        self.vulnDashboard = res.data;
        self.mostCommonVuln = res.data.most_common_vulnerabilities;
        self.severeVuln = res.data.most_severe_vulnerabilities;
        self.vuln_cvss = res.data.cvss_score_based_vulns;
        this.cvss_values = [
          self.vuln_cvss.low_cvss_score,
          self.vuln_cvss.medium_cvss_score,
          self.vuln_cvss.high_cvss_score,
          self.vuln_cvss.critical_cvss_score,
        ];
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
@import "vue-select/src/scss/vue-select.scss";
[dir] .vs--searchable .vs__dropdown-toggle {
  padding-bottom: 6px !important;
  padding-top: 4px !important;
  // background-color: white;
  // border: 1px solid #7367f0;
}
[dir="ltr"] .vs__search,
[dir="ltr"] .vs__search:focus {
  color: #6e6b7b;
}

.dark-layout .vs__search,
[dir="ltr"] .vs__search:focus {
  color: #b4b7bd;
}
.dark-layout .vs--searchable .vs__dropdown-toggle {
  padding-bottom: 6px;
  padding-top: 4px;
  // background-color: #283046;
  // background-color: rgba(0, 207, 232, 0.12);
}
</style>
