<template>
  <b-card-code title="Top Common Vulnerabilities">
    <b-table
      responsive
      id="orgTable"
      :fields="fields"
      :items="mostCommonVuln"
      
      style="text-transform: capitalize"
    >
      <template #cell(count)="data">
        <div class="d-flex justify-content-center">
          <b-avatar size="sm" variant="secondary" :text="JSON.stringify(data.item.count)" />
        </div>
      </template>
    </b-table>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable, BBadge, BAvatar } from "bootstrap-vue";
export default {
  components: {
    BCardCode,
    BTable,
    BBadge,
    BAvatar,
  },
  props: {
     mostCommonVuln:{
      type:Array,
      required:true,
    },
  },
  data() {
    return {
      // Call orgaization API and return in this format
      items: [],
      fields: [
        { key: "name", label: "Name" },
        // { key: "severity", label: "Severity" },
        { key: "count", label: "Count" },
      ],
    };
  },

  methods: {

  },
};
</script>