<template>
  <b-row>
    <b-col lg="12">
      <b-card class="border-primary rounded">
        <div class="d-flex align-items-center">
          <span class="text-primary h3 mr-1">
            <b-avatar
              v-if="vulnCount.vulnerability_count.total_vulnerabilities"
              size="md"
              variant="light-primary"
              :text="
                JSON.stringify(
                  vulnCount.vulnerability_count.total_vulnerabilities
                )
              "
            />
            <b-avatar v-else size="md" variant="light-primary" text="0" />
          </span>
          <h4 class="text-primary text-center">Total Vulnerabilities</h4>
        </div>
        <b-row>
          <b-col
            ><div
              class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card"
              @click="totalWithQuarterDates()"
            >
              <span class="h5 text-primary">{{
                vulnCount.vulnerability_count.total_vulnerabilities
              }}</span
              ><br /><span class="text-primary h6">Total</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card"
              @click="openWithQuarterDates()"
            >
              <span class="h5 text-primary">{{
                vulnCount.vulnerability_count.open_vulnerability
              }}</span
              ><br /><span class="text-primary h6">Open</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-primary rounded text-center bg-blue-grad bg-light-primary cursor-pointer custom-card"
              @click="closeWithQuarterDates()"
            >
              <span class="h5 text-primary">{{
                vulnCount.vulnerability_count.closed_vulnerabilities
              }}</span
              ><br /><span class="text-primary h6">Closed</span>
            </div></b-col
          >
        </b-row>
      </b-card>
    </b-col>
    <b-col lg="12">
      <b-card class="border-primary rounded">
        <div class="d-flex align-items-center">
          <span class="text-primary h3 mr-1">
            <b-avatar
              v-if="
                vulnCount.vulnerability_count.open_vulnerabilities
                  .total_open_vulns
              "
              size="md"
              variant="light-primary"
              :text="
                JSON.stringify(
                  vulnCount.vulnerability_count.open_vulnerabilities
                    .total_open_vulns
                )
              "
            />
            <b-avatar v-else size="md" variant="light-primary" text="0" />
          </span>
          <h4 class="text-primary text-center">Open Vulnerabilities</h4>
        </div>
        <b-row>
          <b-col>
            <div
              class="border-purple rounded text-center bg-blue-grad bg-purple cursor-pointer custom-card"
              @click="navigateToPage('critical')"
            >
              <span class="h5 text-purple">
                {{
                  vulnCount.vulnerability_count.open_vulnerabilities
                    .critical_open_vulns
                }}</span
              ><br /><span class="text-purple h6">Critical</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-danger rounded text-center bg-light-danger bg-blue-grad cursor-pointer custom-card"
              @click="navigateToPage('high')"
            >
              <span class="h5 text-danger">{{
                vulnCount.vulnerability_count.open_vulnerabilities
                  .high_open_vulns
              }}</span
              ><br /><span class="text-danger h6">High</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-warning rounded text-center bg-blue-grad bg-light-warning cursor-pointer custom-card"
              @click="navigateToPage('medium')"
            >
              <span class="h5 text-warning">{{
                vulnCount.vulnerability_count.open_vulnerabilities
                  .medium_open_vulns
              }}</span
              ><br /><span class="text-warning h6">Medium</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-success rounded text-center bg-blue-grad bg-light-success cursor-pointer custom-card"
              @click="navigateToPage('low')"
            >
              <span class="h5 text-success">{{
                vulnCount.vulnerability_count.open_vulnerabilities
                  .low_open_vulns
              }}</span
              ><br /><span class="text-success h6">Low</span>
            </div></b-col
          >
          <b-col
            ><div
              class="border-info rounded text-center bg-blue-grad bg-light-info cursor-pointer custom-card"
              @click="navigateToPage('info')"
            >
              <span class="h5 text-info">{{
                vulnCount.vulnerability_count.open_vulnerabilities
                  .info_open_vulns
              }}</span
              ><br /><span class="text-info h6">Info</span>
            </div></b-col
          >
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BAvatar,
} from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    // VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    vulnCount: {
      type: Object,
      required: true,
    },
    groupid: {
      type: Number,
      required: true,
    },
    quarter: {
      type: String,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      vulnCount: {},
      fromDate: "",
      endDate: "",
    };
  },
  methods: {
    calculateQuarterDates() {
      const currentYear = this.year;
      let quarterStartDate, quarterEndDate;
      switch (this.quarter) {
        case "Q1":
          quarterStartDate = moment(`${currentYear}-01-01`);
          quarterEndDate = moment(`${currentYear}-03-31`);
          break;
        case "Q2":
          quarterStartDate = moment(`${currentYear}-04-01`);
          quarterEndDate = moment(`${currentYear}-06-30`);
          break;
        case "Q3":
          quarterStartDate = moment(`${currentYear}-07-01`);
          quarterEndDate = moment(`${currentYear}-09-30`);
          break;
        case "Q4":
          quarterStartDate = moment(`${currentYear}-10-01`);
          quarterEndDate = moment(`${currentYear}-12-31`);
          break;
        default:
          break;
      }
      this.fromDate = quarterStartDate.format("YYYY-MM-DD");
      this.endDate = quarterEndDate.format("YYYY-MM-DD");
    },
    totalWithQuarterDates() {
      this.calculateQuarterDates();
      this.$router.push({
        path: "/assets/vulnerabilities",
        query: {
          group_id: this.groupid,
          tabIndex: 1,
          fromDate: this.fromDate, // Include fromDate
          endDate: this.endDate, // Include endDate
        },
      });
    },
    openWithQuarterDates() {
      this.calculateQuarterDates();
      this.$router.push({
        path: "/assets/vulnerabilities",
        query: {
          group_id: this.groupid,
          tabIndex: 1,
          fromDate: this.fromDate, // Include fromDate
          endDate: this.endDate, // Include endDate
          status: [0, 3, 5],
        },
      });
    },

    closeWithQuarterDates() {
      this.calculateQuarterDates();
      this.$router.push({
        path: "/assets/vulnerabilities",
        query: {
          group_id: this.groupid,
          tabIndex: 1,
          fromDate: this.fromDate, // Include fromDate
          endDate: this.endDate, // Include endDate
          status: [1],
        },
      });
    },
    navigateToPage(severity) {
      this.calculateQuarterDates();
      let severityParam = ""; // Initialize severity parameter

      if (severity === "critical") {
        severityParam = "critical";
      } else if (severity === "high") {
        severityParam = "high";
      } else if (severity === "medium") {
        severityParam = "medium";
      } else if (severity === "low") {
        severityParam = "low";
      } else if (severity === "info") {
        severityParam = "info";
      }

      this.$router.push({
        path: "/assets/vulnerabilities",
        query: {
          severity: severityParam,
          fromDate: this.fromDate,
          endDate: this.endDate,
          group_id: this.groupid,
          status: [0, 3, 5],
          tabIndex: 1,
        },
      });
    },
  },
};
</script>
<style scoped>
.bg-blue-grad {
  /* background: linear-gradient(to bottom right,#825cf9,#8192d3); */
  color: #ffff !important;
  padding: 0.5rem;
}
.custom-card {
  transition: all 0.2s linear;
}

.custom-card:hover {
  transform: scale(1.05);
}

.bg-purple {
  background: rgb(160 32 240 / 22%) !important;
}
.text-purple {
  color: #a020f0 !important;
}
.border-purple {
  border: 1px solid #a020f0 !important;
}
</style>